import React, { useEffect, useState } from "react";
import "../Styles/PayScreen.css";
import { QRCode } from "react-qrcode-logo";
import { useParams } from "react-router-dom";
import db from "../firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";

function PayScreen() {
  const { document_id } = useParams();

  const [upiId, setUpiId] = useState("");
  const [username, setUsername] = useState("");
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const [upiLink, setUpiLink] = useState("");

  const pay_amount = async () => {
    const pay_click_ref = collection(db, "pay_clicks");

    await setDoc(doc(pay_click_ref), {
      upi_id: upiId,
      username,
      amount,
      message,
      link: upiLink,
      generated_link_id: document_id,
      timestamp: new Date(),
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    window.location.replace(upiLink);
  };

  useEffect(() => {
    const docRef = doc(db, "successful_links", document_id);
    getDoc(docRef).then((response) => {
      setUpiId(response.data().upi_id);
      setUpiLink(response.data().link);
      setUsername(response.data().username);
      setAmount(response.data().amount);
      setMessage(response.data().message);
    });

    return () => {};
  }, [document_id]);

  return (
    <div className="App">
      <div className="header">
        <h1>Pay Online using any UPI Application</h1>
      </div>

      <div className="row">
        <div className="col-6 menu">
          <ul>
            <li>UPI ID: {upiId}</li>
            <li>Username: {username}</li>
            <li>Amount: {amount}</li>
            <li>Message: {message}</li>
          </ul>
        </div>

        <div className="col-6 qr-side">
          <QRCode value={upiLink} />
        </div>
        <div color="button-section">
          {/* <a href={upiLink}> */}
          <button onClick={pay_amount} className="pay-button">
            Pay Now
          </button>
          {/* </a> */}
        </div>
      </div>

      {/* <div className="footer"></div> */}
    </div>
  );
}

export default PayScreen;
