import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCqFaIsgntZkVwADakZvT4i3p-3-WSQeEE",
  authDomain: "upi-generator.firebaseapp.com",
  projectId: "upi-generator",
  storageBucket: "upi-generator.appspot.com",
  messagingSenderId: "6597993368",
  appId: "1:6597993368:web:9b376dc57068136b8d01ed",
  measurementId: "G-WE304Z3G5Q",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const Provider = new firebase.auth.GoogleAuthProvider();

export { auth, Provider };
export default db;
